import React, { useEffect, useState } from 'react';
import { useLocation, matchPath, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from '../../../store';
import PropTypes from 'prop-types';
import { getCourse } from 'src/slices/videos';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  TextField
} from '@material-ui/core';

function renderNavItems({ items, pathname, depth = 0, courseSlug }) {
  return (
    <List disablePadding>
      {items?.reduce((acc, item) => {
        return reduceChildRoutes({ acc, item, pathname, depth, courseSlug });
      }, [])}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, courseSlug }) {
  const key = item.title + depth;

  if (item.videos) {
    const lesson = item;
    const reversePath = pathname.split('/').reverse();
    const pathNotFound = reversePath[0] == courseSlug;
    const open = matchPath(`/${reversePath[1]}/${reversePath[0]}`, {
      path: `/${lesson.slug}`,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={lesson.icon}
        info={lesson.info}
        key={key}
        open={Boolean(open) || pathNotFound}
        lessonSlug={lesson.slug}
        title={lesson.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: lesson.videos
        })}
      </NavItem>
    );
  } else {
    const video = item;
    acc.push(
      <NavItem
        depth={depth}
        videoSlug={video.slug}
        videoId={video.id}
        lessonSlug={video.lessonSlug}
        key={key}
        title={video.title}
      />
    );
  }
  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const [search, setSearch] = useState(null);
  //hooks
  const { user } = useAuth();
  const classes = useStyles();
  let { courseSlug } = useParams();

  //states
  const courses = useSelector(state => state.videos.courses);
  const course = courses[courseSlug];
  console.log('RRRR: ', courses);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourse(courseSlug));
  }, [dispatch]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink
              to="/app"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/profile">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.profile.image}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.profile.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              Your tier:{' '}
              <Link component={RouterLink} to="/app/profile">
                {user.profile.mentor ? 'Mentor' : 'Student'}
              </Link>
            </Typography>
          </Box>
          <TextField
            label="Search"
            size="small"
            onChange={e => setSearch(e.target.value.toLowerCase())}
          />
        </Box>
        <Divider />
        <Box p={2}>
          {
            <List
              key={course?.id}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {course?.title}
                </ListSubheader>
              }
            >
              {console.log(course?.lessons) && <></>}
              {renderNavItems({
                items: course?.lessons
                  .map(lesson => {
                    const videos = lesson.videos.filter(
                      a =>
                        !search || a.title.toLowerCase().indexOf(search) !== -1
                    );
                    return { ...lesson, videos };
                  })
                  .filter(lesson => lesson.videos.length > 0),
                pathname: location.pathname,
                courseSlug
              })}
            </List>
          }
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
