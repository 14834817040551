import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  comments: []
};

const slice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    getComments(state, action) {
      const comments = action.payload;
      state.comments = comments;
    },
    createComment(state, action) {
      const comment = action.payload;
      state.comments.push(comment);
    },

    deleteComment(state, action) {
      const { commentId } = action.payload;
      state.comments = _.reject(state.comments, { id: commentId });
    }
  }
});

export const reducer = slice.reducer;

//api calls
//get
export const getComments = studentId => async dispatch => {
  try {
    const response = await axios.get(`/students/${studentId}/comments/`);
    dispatch(slice.actions.getComments(response.data));
  } catch (error) {
    console.log('slices > comments.js > getComments', error);
  }
};
//create
export const createComment = data => async dispatch => {
  try {
    const response = await axios.post(
      `/students/${data.studentId}/comments/`,
      data
    );
    dispatch(slice.actions.createComment(response.data));
  } catch (error) {
    console.log('slices > comments.js > createComment', error);
  }
};

//delete
export const deleteComment = (commentId, studentId) => async dispatch => {
  try {
    await axios.delete(`/students/${studentId}/comments/${commentId}`, {
      commentId
    });
    dispatch(slice.actions.deleteComment({ commentId }));
  } catch (error) {
    console.log('slices > comments.js > deleteComment', error);
  }
};

export default slice;
