import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  tracks: []
};

const slice = createSlice({
  name: 'tracks',
  initialState,
  reducers: {
    getTracks(state, action) {
      state.tracks = action.payload;
      state.tracks.map(track =>
        track.course.sort((a, b) => a.position - b.position)
      );
    }
  }
});

export const reducer = slice.reducer;

export const getTracks = () => async dispatch => {
  try {
    const response = await axios.get(`/tracks`);
    dispatch(slice.actions.getTracks(response.data));
  } catch (error) {
    console.log('slices > tracks.js > getTracks', error);
  }
};

export default slice;
