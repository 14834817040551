import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as formReducer } from 'redux-form';
import { reducer as tasksReducer } from 'src/slices/tasks';
import { reducer as reportsReducer } from 'src/slices/reports';
import { reducer as authReducer } from 'src/slices/auth';
import { reducer as profileReducer } from 'src/slices/profile';
import { reducer as studentReducer } from 'src/slices/student';
import { reducer as mentorReducer } from 'src/slices/mentor';
import { reducer as commentsReducer } from 'src/slices/comments';
import { reducer as lecturesReducer } from 'src/slices/lectures';
import { reducer as videosReducer } from 'src/slices/videos';
import { reducer as tracksReducer } from 'src/slices/tracks';
import { reducer as progressReducer } from 'src/slices/progress';
import { reducer as zoomReducer } from 'src/slices/zoom';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  form: formReducer,
  tasks: tasksReducer,
  reports: reportsReducer,
  auth: authReducer,
  profile: profileReducer,
  student: studentReducer,
  mentor: mentorReducer,
  comments: commentsReducer,
  lectures: lecturesReducer,
  videos: videosReducer,
  tracks: tracksReducer,
  progress: progressReducer,
  zoom: zoomReducer
});

export default rootReducer;
