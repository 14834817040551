import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  tracks: [],
  courses: {},
  allCourses: []
};

const slice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    //lessons
    getCourse(state, action) {
      console.log('TESTING', action.payload.slug);

      // state.course = action.payload;
      if (!state.courses[action.payload.slug]) {
        state.courses[action.payload.slug] = action.payload;
      }
      let course = state.courses[action.payload.slug];
      course.lessons.sort((a, b) => a.position - b.position);
      course.lessons = course.lessons.map(injectLessonSlug);
    },

    getAllCoursesOfTrack(state, action) {
      state.allCourses = action.payload;
    }
  }
});

const injectLessonSlug = lesson => {
  lesson.videos.sort((a, b) => a.position - b.position);
  return {
    ...lesson,
    videos: lesson.videos.map(video => ({
      ...video,
      lessonSlug: lesson.slug
    }))
  };
};

export const reducer = slice.reducer;

//get a single course with its lessons
export const getCourse = courseSlug => async dispatch => {
  try {
    const response = await axios.get(`/course/${courseSlug}/lessons`);
    dispatch(slice.actions.getCourse(response.data));
    console.log('Hello?');
  } catch (error) {
    console.log('slices > videos.js > getCourse', error);
  }
};

// this method is to get all courses, lessons, and videos of a track
export const getAllCoursesOfTrack = trackId => async dispatch => {
  try {
    const response = await axios.get(`/allcourses/${trackId}`);
    dispatch(slice.actions.getAllCoursesOfTrack(response.data));
  } catch (error) {
    console.log('slices > tracks.js > getAllCoursesOfTrack', error);
  }
};

export const clearIsLoading = slice.actions.clearIsLoading;

export default slice;

/*

Tracks: [
  iOS: [
    Course 1: , 
    Course 2
  ],
  Android: [
    Course 1
  ]
]


Course: [
  lesson: [
    lesson1:[
      video1: {},
      video2: {}
    ]
  ] 
]

*/
