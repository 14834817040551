import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  tasks: []
};

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    getTasks(state, action) {
      const tasks = action.payload;
      state.tasks = tasks;
    },
    createTask(state, action) {
      const task = action.payload;
      state.tasks.push(task);
    },
    updateTask(state, action) {
      const data = action.payload.payload;
      state.tasks = _.map(state.tasks, _task => {
        if (_task.id === data.id) {
          return data;
        }
        return _task;
      });
    },
    deleteTask(state, action) {
      const { taskId } = action.payload;
      state.tasks = _.reject(state.tasks, { id: taskId });
    }
  }
});

export const reducer = slice.reducer;

//api calls
//get
export const getTasks = () => async dispatch => {
  try {
    const response = await axios.get(`/track/tasks`);
    dispatch(slice.actions.getTasks(response.data));
  } catch (error) {
    console.log('slices > tasks.js > getTasks', error);
  }
};

//create
export const createTask = data => async dispatch => {
  try {
    const response = await axios.post(`/track/tasks`, data);
    dispatch(slice.actions.createTask(response.data));
  } catch (error) {
    console.log('slices > tasks.js > createTask', error);
  }
};

//update
export const updateTask = data => async dispatch => {
  try {
    const response = await axios.put(`/track/tasks/${data.taskId}`, data);
    dispatch(slice.actions.updateTask(response.data));
  } catch (error) {
    console.log('slices > tasks.js > updateTask', error);
  }
};

//delete
export const deleteTask = taskId => async dispatch => {
  try {
    await axios.delete(`/track/tasks/${taskId}`, {
      taskId
    });
    dispatch(slice.actions.deleteTask({ taskId }));
  } catch (error) {
    console.log('slices > tasks.js > deleteTask', error);
  }
};
export const submitTask = submition => async dispatch => {
  try {
    await axios.post(`/submitTask`, submition);
  } catch (error) {
    console.log('slices > tasks.js > deleteTask', error);
  }
};
export default slice;
