import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  progress: {},
  userProgress: {}
};

const slice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    getProgress(state, action) {
      // we need.data here because we're not falttening in user progress
      state.progress[action.payload.slug] = action.payload.data;
    },

    createProgress(state, action) {
      state.progress[action.payload.slug] = action.payload;
    },

    updateProgress(state, action) {
      const newProgress = action.payload;
      const slug = action.payload.slug;
      state.progress[slug] = newProgress;
    },

    getUserProgress(state, action) {
      state.userProgress[action.payload.id] = action.payload.data;
    }
  }
});

export const reducer = slice.reducer;

//api calls
//get
export const getProgress = data => async dispatch => {
  try {
    const response = await axios.get(
      `/progress/${data.profileId}/${data.videoId}`
    );
    dispatch(
      slice.actions.getProgress({ data: response.data, slug: data.slug })
    );
  } catch (error) {
    console.log(
      'slices > progress.js > getProgress',
      error,
      '\n\nwith data:\n',
      data
    );
  }
};

//create
export const createProgress = data => async dispatch => {
  let res = await axios.get(`/progress/${data.profileId}/${data.videoId}`);
  if (res.data)
    return console.log('already there', data.profileId, data.videoId);
  try {
    const response = await axios.post(
      `/progress/${data.profileId}/${data.videoId}`,
      data
    );
    dispatch(
      slice.actions.createProgress({
        ...response.data,
        slug: data.slug
      })
    );
  } catch (error) {
    console.log('slices > progress.js > createProgress', error);
  }
};

//update
export const updateProgress = data => async dispatch => {
  try {
    const response = await axios.put(`/progress/0/0/${data.id}`, data);
    dispatch(
      slice.actions.updateProgress({
        ...response.data.payload,
        slug: data.slug
      })
    );
  } catch (error) {
    console.log('slices > progress.js > updateProgress', error);
  }
};

//get a specific user's video progress
export const getUserProgress = profileId => async dispatch => {
  try {
    const response = await axios.get(`/user/progress/${profileId}`);
    dispatch(
      slice.actions.getUserProgress({ data: response.data, id: profileId })
    );
    console.log(
      'createProgress videoStateCal Hello? I am working in progress',
      response.data
    );
  } catch (error) {
    console.log('slices > progress.js > getProgress', error);
  }
};

export default slice;
