import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  students: [],
  attendance: [],
  queriedStudent: {}
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    // Changes `queriedStudent`
    getStudentProfile(state, action) {
      state.queriedStudent = action.payload;
    },
    getStudents(state, action) {
      state.students = action.payload;
    },
    getAttendance(state, action) {
      state.attendance = action.payload;
    },
    attendanceChange(state, action) {
      const newAttendance = [
        ...state.attendance.filter(
          a =>
            !(
              a.lectureId === action.payload.lectureId &&
              a.studentId === action.payload.studentId
            )
        )
      ];
      if (action.payload.data) newAttendance.push(action.payload.data);
      state.attendance = newAttendance;
    },
    setStudents(state, action) {
      state.students = action.payload;
    },
    setAttendance(state, action) {
      // ! something is wrong here I don't even need this file
      // state.students.lectures = action.payload.lectureId;
    },
    updateSubmissions(state, action) {
      const {
        submissionResponse,
        taskId,
        studentId,
        submission
      } = action.payload;
      const students = submission
        ? submission.Students.map(a => a.id)
        : [studentId];
      state.students = state.students.map(student => {
        if (students.includes(student.id)) {
          student.tasks = student.tasks.map(task => {
            if (task.taskId === taskId) {
              task.submissions = [...task.submissions, submissionResponse];
            }
            return task;
          });
        }
        return student;
      });
    }
  }
});

export const reducer = slice.reducer;

export const getStudentProfile = studentId => async dispatch => {
  try {
    const response = await axios.get(`/students/${studentId}`);
    dispatch(slice.actions.getStudentProfile(response.data));
  } catch (error) {
    console.log('slices > profile.js > getProfile', error);
  }
};

export const getStudents = (
  trackId,
  cohortId,
  mentorSelect
) => async dispatch => {
  try {
    const response = await axios.get(
      `/tracks/students` + (mentorSelect !== 'all' ? `/${mentorSelect}` : '')
    );

    dispatch(slice.actions.getStudents(response.data));
  } catch (error) {
    console.log('slices > students.js > getStudents', error);
  }
};

export const getAttendance = () => async dispatch => {
  try {
    const response = await axios.get(`/attendance`);

    dispatch(slice.actions.getAttendance(response.data));
  } catch (error) {
    console.log('slices > students.js > getAttendance', error);
  }
};

export const setStudents = students => dispatch => {
  console.log('hi');
  dispatch(slice.actions.setStudents(students));
};

export const attendanceChange = (
  studentId,
  lectureId,
  value
) => async dispatch => {
  try {
    const response = await axios.get(
      `/students/${studentId}/attend/${lectureId}/${value}`
    );
    dispatch(
      slice.actions.attendanceChange({
        data: response.data,
        studentId,
        lectureId
      })
    );
  } catch (error) {
    console.log('slices > students.js > attendanceChange', error);
  }
};
export const setAttendance = data => async dispatch => {
  console.log('I am at setAttendance', data);
  try {
    const response = await axios.post(`/attendance`, data);
    dispatch(slice.actions.setAttendance(response.data));
  } catch (error) {
    console.log('slices > students.js > setAttendance', error);
  }
};

export const updateSubmission = (
  taskId,
  submission,
  studentId,
  value
) => async dispatch => {
  try {
    let updatedOrCreatedResponse = await axios.post(`/submissions`, {
      studentId,
      taskId,
      submission,
      ...value
    });
    const submissionResponse = updatedOrCreatedResponse.data.payload;
    dispatch(
      slice.actions.updateSubmissions({
        submissionResponse,
        taskId,
        submission,
        studentId,
        value
      })
    );
  } catch (error) {
    console.log('🚀 error', error);
  }
};

export default slice;
