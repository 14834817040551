import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';

const paths = {
  LOGIN: 'account/login/',
  RESET: '/account/reset',
  REGISTER: 'account/register'
  // SIGNOUT: 'account/signout'
};

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isMentor: false,
  isStudent: false,
  isOps: false,
  isAdmin: false,
  user: null,
  cohorts: [],
  userType: null
};

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = accessToken => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('meetingId');
    localStorage.removeItem('zoomToken');
    localStorage.removeItem('currentCohort');
    delete axios.defaults.headers.common.Authorization;
  }
};
const setCohortHeader = (cohort, track) => {
  if (cohort && track) {
    axios.defaults.headers.common.currentCohort = cohort;
    axios.defaults.headers.common.currentTrack = track;
  }
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, cohorts } = action.payload;
      const { currentUser } = getUserBasedOnCohort(user, cohorts, null);
      const { isUsersObject, userType } = getUserType(currentUser);

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user: currentUser,
        userType,
        cohorts,
        ...isUsersObject
      };
    }
    case 'LOGIN': {
      const { user, cohorts } = action.payload;
      setupUserInstance(user);
      const { currentUser } = getUserBasedOnCohort(user, cohorts, null);
      const { isUsersObject, userType } = getUserType(currentUser);

      return {
        ...state,
        isAuthenticated: true,
        user: currentUser,
        userType,
        cohorts,
        ...isUsersObject
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;
      setupUserInstance(user);
      const { isUsersObject, userType } = getUserType(user);

      return {
        ...state,
        isAuthenticated: true,
        user,
        userType,
        ...isUsersObject
      };
    }
    case 'CHANGE_COHORT': {
      const { id } = action.payload;
      const { currentUser } = getUserBasedOnCohort(
        state.user,
        state.cohorts,
        id
      );
      const { isUsersObject, userType } = getUserType(currentUser);

      return {
        ...state,
        user: currentUser,
        userType,
        ...isUsersObject
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  changeCohort: () => {},
  reset: () => Promise.resolve(),
  have_permission: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const have_permission = permission => {
    if (!state.user) return false;
    const cohortGroup = state.user.selectedCohort;

    if (cohortGroup.role === 'student') return false;
    if (permission && !cohortGroup.role_data.permissions.includes(permission))
      return false;
    return true;
  };
  const login = async (email, password) => {
    const response = await axios.post(paths.LOGIN, {
      username: email,
      password
    });
    const { accessToken, user, cohorts } = response.data;

    setupUserInstance(user);
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        cohorts
      }
    });
  };
  const reset = async (resetCode, password) => {
    console.log(resetCode, password);
    const response = await axios.post(paths.RESET, {
      resetCode,
      password
    });
    const { accessToken, user, cohorts } = response.data;
    const trackId = cohorts?.find(cohort => cohort?.cohort?.activated)?.trackId;
    let currentUser = user;
    const { userType } = getUserType(user);
    if (userType === 'student')
      currentUser = {
        ...user,
        profile: {
          ...user.profile,
          student: { ...user.profile.student, trackId }
        }
      };
    setupUserInstance(user);
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: currentUser,
        cohorts
      }
    });
  };
  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };
  const changeCohort = id => {
    window.localStorage.setItem('currentCohort', id);
    dispatch({ type: 'CHANGE_COHORT', payload: { id } });
  };
  const register = async (email, name, password, userType) => {
    const response = await axios.post(paths.REGISTER, {
      username: email,
      name,
      password,
      userType
    });

    const { accessToken, user } = response.data;
    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        userType
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/account/me');
          const { user, cohorts } = response.data;

          setupUserInstance(user);

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
              cohorts
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        reset,
        changeCohort,
        have_permission
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
   This function will take user and return 2 objects
   1. `isUsersObject`: an object that will return 4 questions 
   ```
   {
     isMentor, 
     isStudent, 
     isOps, 
     isAdmin
   }
    ```
   2. `userType`: a single string value that will return a single permission for the user. 
   if the user was both mentor and student, `userType` would be only mentor
*/
const getUserType = user => {
  let users = {
    isMentor: false,
    isStudent: false,
    isOps: false,
    isAdmin: false
  };
  let userType = null;

  if (user?.profile?.mentor) {
    users.isMentor = true;
    userType = 'mentor';
  }
  if (user?.profile?.student) {
    users.isStudent = true;
    userType = 'student';
  }
  if (user?.profile?.admin) {
    users.isAdmin = true;
    userType = 'admin';
  }
  if (user?.profile?.ops) {
    users.isOps = true;
    userType = 'ops';
  }
  return { isUsersObject: users, userType };
};

const getUserBasedOnCohort = (user, cohorts, newCohortId) => {
  if (!newCohortId) {
    newCohortId = +window.localStorage.getItem('currentCohort');
  }
  const myCohort = cohorts?.find(cohort =>
    newCohortId ? cohort?.id === newCohortId : cohort?.cohort?.activated
  );

  const cohort = { ...myCohort?.cohort, myCohortId: myCohort?.id };
  const trackId = myCohort?.trackId;
  let currentUser = user;
  if (cohort && trackId) setCohortHeader(cohort.id, trackId);

  // trying to make sense of old system :(
  if (myCohort) {
    if (myCohort?.role === 'student') {
      currentUser = {
        ...user,
        profile: {
          ...user.profile,
          student: {
            ...user.profile.student,
            ...user.profile.oldStudent,
            trackId,
            cohort
          },
          oldMentor: user.profile.mentor,
          mentor: null
        }
      };
    } else {
      currentUser = {
        ...user,
        profile: {
          ...user.profile,
          mentor: {
            ...user.profile.mentor,
            ...user.profile.oldMentor,
            trackId,
            cohort
          },
          oldStudent: user.profile.student,
          student: null
        }
      };
    }

    return { currentUser: { ...currentUser, selectedCohort: myCohort } };
  } else return { currentUser: null };
};
/**
 *
 * This function will faltten avatar and  name of the user
 */
const setupUserInstance = user => {
  // user.avatar = user.profile.image;
  // user.name = user.profile.name;
  user.profile.email = user.email;
};
export default AuthContext;
