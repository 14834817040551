import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  events: []
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state, action) {
      const events = action.payload;

      state.events = events;
    }
  }
});

export const reducer = slice.reducer;

export const getEvents = trackId => async dispatch => {
  const response = await axios.get(`/events`, { params: { trackId } });
  dispatch(slice.actions.getEvents(response.data));
};

export default slice;
