import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  lectures: [],
  material: []
};

const slice = createSlice({
  name: 'lectures',
  initialState,
  reducers: {
    //lectures
    getLectures(state, action) {
      const lecture = action.payload;
      state.lectures = lecture;
    },
    createLectures(state, action) {
      const lecture = action.payload;
      state.lectures.push(lecture);
    },

    deleteLectures(state, action) {
      const { lectureId } = action.payload;
      state.lectures = _.reject(state.lectures, { id: lectureId });
    },

    //material
    getMaterial(state, action) {
      const material = action.payload;
      state.material = material;
    },
    createMaterial(state, action) {
      const material = action.payload;
      state.material.push(material);
    },

    deleteMaterial(state, action) {
      const { materialId } = action.payload;
      state.material = _.reject(state.material, { id: materialId });
    }
  }
});

export const reducer = slice.reducer;

//lectures api calls
//get
export const getLectures = () => async dispatch => {
  try {
    const response = await axios.get('/lectures');
    dispatch(slice.actions.getLectures(response.data));
  } catch (error) {
    console.log('slices > lectures.js > getLectures', error);
  }
};

//create
export const createLectures = data => async dispatch => {
  try {
    const response = await axios.post('/lectures', data);
    dispatch(slice.actions.createLectures(response.data));
  } catch (error) {
    console.log('slices > lectures.js > createLectures', error);
  }
};

//delete
export const deleteLectures = lectureId => async dispatch => {
  try {
    await axios.delete(`/lectures/${lectureId}`, {
      lectureId
    });
    dispatch(slice.actions.deleteLectures({ lectureId }));
  } catch (error) {
    console.log('slices > lectures.js > deleteLectures', error);
  }
};

//material api calls
//get
export const getMaterial = lectureId => async dispatch => {
  try {
    const response = await axios.get('/material/' + lectureId);

    dispatch(slice.actions.getMaterial(response.data));
  } catch (error) {
    console.log('slices > lectures.js > getMaterial', error);
  }
};

//create
export const createMaterial = data => async dispatch => {
  try {
    const response = await axios.post('/material', data);
    dispatch(slice.actions.createMaterial(response.data));
  } catch (error) {
    console.log('slices > lectures.js > createMaterial', error);
  }
};

//delete
export const deleteMaterial = materialId => async dispatch => {
  try {
    await axios.delete(`/material/${materialId}`, {
      materialId
    });
    dispatch(slice.actions.deleteMaterial({ materialId }));
  } catch (error) {
    console.log('slices > lectures.js > deleteMaterial', error);
  }
};

export default slice;
