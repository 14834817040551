import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  mentors: []
};

const slice = createSlice({
  name: 'mentors',
  initialState,
  reducers: {
    // Changes `queriedStudent`
    getMentors(state, action) {
      state.mentors = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getMentors = trackId => async dispatch => {
  try {
    const response = await axios.get(`/mentors`, { params: { trackId } });
    dispatch(slice.actions.getMentors(response.data));
  } catch (error) {
    console.log('slices > mentors.js > getMentor', error);
  }
};

export default slice;
