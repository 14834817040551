import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  reports: [],
  allReports: []
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    getReports(state, action) {
      const reports = action.payload;
      state.reports = reports;
    },
    getAllReports(state, action) {
      const allReports = action.payload;
      state.allReports = allReports;
    }
  }
});

export const reducer = slice.reducer;

export const getReports = trackId => async dispatch => {
  try {
    const response = await axios.get(`/reports/track/`);
    dispatch(slice.actions.getReports(response.data));
  } catch (error) {
    console.log('slices > reports.js > getReports', error);
  }
};
export const getAllReports = trackId => async dispatch => {
  try {
    const response = await axios.get(`/reports/all/`);
    dispatch(slice.actions.getAllReports(response.data));
  } catch (error) {
    console.log('slices > reports.js > getAllReports', error);
  }
};

export default slice;
