/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from '../../../store';
import { getTracks } from 'src/slices/tracks';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  Select,
  MenuItem
} from '@material-ui/core';
// import Note from '@material-ui/icons/Note';
import {
  Calendar as CalendarIcon,
  Code as CodeIcon,
  Users as UsersIcon,
  Clipboard as ClipboardIcon,
  PieChart as PieChartIcon,
  Youtube as VideosIcon,
  Settings as SettingsIcon,
  FileText as ReportsIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

// Mentors stuff
const mentorsSections = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      },
      // {
      //   title: 'Reports',
      //   icon: ReportsIcon,
      //   href: '/app/reports/all'
      // },
      {
        title: 'Lectures',
        icon: ClipboardIcon,
        href: '/dashboard/lectures'
      },
      {
        title: 'Course Details',
        icon: ReportsIcon,
        href: '/dashboard/notion'
      },
      {
        title: 'Students',
        icon: UsersIcon,
        href: '/dashboard/students'
      },
      {
        title: 'Tasks',
        icon: CodeIcon,
        href: '/dashboard/tasks'
      },
      {
        title: 'Calendar',
        icon: CalendarIcon,
        href: '/app/calendar'
      },
      {
        title: 'Videos',
        icon: VideosIcon,
        href: '/app/videos'
      },
      {
        title: 'Settings',
        icon: SettingsIcon,
        href: '/app/settings'
      }
    ]
  }
];
// Students stuff
const studentsSections = [
  {
    subheader: 'Students',
    items: [
      {
        title: 'Lectures',
        icon: ClipboardIcon,
        href: '/dashboard/lectures'
      },
      // {
      //   title: 'Mentors',
      //   icon: UsersIcon,
      //   href: '/dashboard/mentors'
      // },

      {
        title: 'Tasks',
        icon: CodeIcon,
        href: '/dashboard/tasks'
      },
      {
        title: 'Calendar',
        icon: CalendarIcon,
        href: '/app/calendar'
      },
      {
        title: 'Videos',
        icon: VideosIcon,
        href: '/app/videos'
      }
    ]
  }
];

const allSections = {
  mentor: mentorsSections,
  student: studentsSections
};

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const dispatch = useDispatch();
  const { tracks } = useSelector(state => state.tracks);
  const classes = useStyles();
  const location = useLocation();
  const { user, userType, cohorts, changeCohort, have_permission } = useAuth();
  const sections = allSections[userType];

  if (!have_permission('REPORTS')) {
    sections[0].items = sections[0].items.filter(a => a.title !== 'Reports');
  }
  if (!have_permission('VIEW_STUDENTS')) {
    sections[0].items = sections[0].items.filter(a => a.title !== 'Students');
  }
  if (!have_permission('ZOOM')) {
    sections[0].items = sections[0].items.filter(a => a.title !== 'Zoom');
  }
  const myCohorts = cohorts.map(cohort => (
    <MenuItem value={cohort.id}>
      {cohort.cohort?.name} -{' '}
      {tracks.find(track => track.id === cohort.trackId)?.name}
    </MenuItem>
  ));

  const currentProfile =
    user.profile.student?.cohort.myCohortId ||
    user.profile.mentor?.cohort.myCohortId;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getTracks());
  }, [dispatch]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink
              to="/app"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/profile">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.profile.image}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/profile"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.profile.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              Your tier:{' '}
              <Link component={RouterLink} to="/app/profile">
                {user.selectedCohort.role ?? 'student'}
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {currentProfile && (
                <Select
                  defaultValue={currentProfile}
                  onChange={e => changeCohort(e.target.value)}
                >
                  {myCohorts}
                </Select>
              )}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
