// import { useSnackbar } from 'notistack';
// import React from 'react';

// const checkTokenExpiry = () => {
// };
const CheckZoomToken = () => {
  const expiryDate = JSON.parse(localStorage?.getItem('zoomToken'))?.[
    'expiryDate'
  ];
  const currentTime = Date.now();
  return expiryDate > currentTime;
  //   const { enqueueSnackbar } = useSnackbar();
  //   checkTokenExpiry()
  //     ? enqueueSnackbar('Loading...', {
  //         variant: 'info'
  //       })
  //     : enqueueSnackbar(
  //         'Your zoom token has expired please sign in again using',
  //         {
  //           variant: 'error'
  //         }
  //       );

  //   console.log('IM HERE');
  //   return <>a</>;
};

export default CheckZoomToken;
