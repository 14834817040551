import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const MentorGuard = ({ children }) => {
  const {
    isAuthenticated,
    have_permission,
    isAdmin,
    isStudent,
    isOps
  } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  if (!have_permission()) {
    if (isAdmin || isOps) {
      return <Redirect to="/soon" />;
    }
    if (isStudent) return <Redirect to="/dashboard/lectures" />;
    return <Redirect to="/404" />;
  } else {
    return <>{children}</>;
  }
};

MentorGuard.propTypes = {
  children: PropTypes.node
};

export default MentorGuard;
