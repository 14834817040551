import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import VideosLayout from 'src/layouts/VideosLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import MentorGuard from 'src/components/MentorGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  //mentorly routes
  {
    path: '/dashboard',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/dashboard/lectures',
        component: lazy(() => import('src/views/material/LectureListView'))
      },
      {
        exact: true,
        path: `/dashboard/material/:lectureId`,
        component: lazy(() => import('src/views/material/MaterialListView'))
      },
      {
        exact: true,
        path: '/dashboard/tasks',
        component: lazy(() => import('src/views/material/TaskListView'))
      },
      {
        exact: true,
        path: '/dashboard/students',
        guard: MentorGuard,
        component: lazy(() => import('src/views/student/StudentListView'))
      },
      {
        exact: true,
        path: '/dashboard/mentors',
        guard: AuthGuard,
        component: lazy(() => import('src/views/student/MentorListView'))
      },
      {
        exact: true,
        path: '/dashboard/notion',
        guard: AuthGuard,
        component: lazy(() => import('src/views/extra/NotionPage'))
      }
    ]
  },
  //
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/soon',
    component: lazy(() => import('src/views/errors/SoonView'))
  },
  {
    exact: true,
    path: '/notrack',
    component: lazy(() => import('src/views/errors/ContactView'))
  },
  {
    exact: true,
    path: '/novideos',
    component: lazy(() => import('src/views/errors/NoVideos'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: () => <Redirect to="/register/student" />
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register/:userType',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset/:resetCode',
    component: lazy(() => import('src/views/auth/ResetView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forget/',
    component: lazy(() => import('src/views/auth/ForgetView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/attend/:jwtCode',
    component: lazy(() => import('src/views/auth/AttendView'))
  },
  {
    path: '/app/videos/courses/:courseSlug',
    guard: AuthGuard,
    layout: VideosLayout,
    routes: [
      {
        exact: true,
        path: '/app/videos/courses/:courseSlug',
        // component: lazy(() => import('src/layouts/VideosLayout'))
        component: lazy(() => import('src/views/videos/LessonView/'))
      },
      {
        exact: true,
        path: '/app/videos/courses/:courseSlug/:lessonSlug/:videoSlug',
        component: lazy(() => import('src/views/videos/VideoView/'))
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/videos',
        component: lazy(() => import('src/views/videos/CourseView/'))
      },
      // Go to any profile
      {
        exact: true,
        path: '/app/profile/',
        component: lazy(() => import('src/views/account'))
      },
      {
        exact: true,
        path: '/app/profile/:profileId',
        component: lazy(() => import('src/views/account'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/'))
      },
      {
        exact: true,
        guard: MentorGuard,
        path: '/app/zoom',
        component: lazy(() => import('src/views/zoom/ConnectZoom'))
      },
      {
        exact: true,
        guard: MentorGuard,
        path: '/app/settings',
        component: lazy(() => import('src/views/settings'))
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        guard: MentorGuard,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        guard: MentorGuard,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        guard: MentorGuard,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        guard: MentorGuard,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },

      // //Go to student profile
      // {
      //   exact: true,
      //   guard: MentorGuard,
      //   path: '/app/management/students/:studentId',
      //   component: lazy(() => import('src/views/account/AccountView'))
      // },

      {
        exact: true,
        guard: MentorGuard,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        guard: MentorGuard,
        path: '/app/reports/all',
        component: lazy(() => import('src/views/reports/ReportsView'))
      },
      {
        exact: true,
        guard: MentorGuard,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() =>
          import('src/views/reports/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        guard: MentorGuard,
        path: '/app/reports',
        component: () => <Redirect to="/app/mentorlyViews/dashboard" />
      },
      {
        exact: true,
        // guard: MentorGuard,
        path: '/app/social',
        component: lazy(() => import('src/views/comments'))
        // component: () => <Redirect to="/app/social/profile" />
      },

      {
        exact: true,
        guard: MentorGuard,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/comments'))
      },

      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/APICallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
