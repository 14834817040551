import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import CheckZoomToken from 'src/utils/CheckZoomToken';

const initialState = {
  meetingData: {},
  recording: {},
  participants: []
};

const slice = createSlice({
  name: 'zoom',
  initialState,
  reducers: {
    getMeetings(state, action) {
      state.meetingData = action.payload;
    },
    getRecording(state, action) {
      state.recording = action.payload;
      const foundMeeting = state.meetingData.meetings.find(
        meeting => meeting.uuid === action.payload.uuid
      );
      foundMeeting.recording = action.payload;
    },
    getParticipants(state, action) {
      state.participants = action.payload;
    }
  }
});

export const reducer = slice.reducer;

//api calls
export const getMeetings = meetingId => async dispatch => {
  try {
    if (!CheckZoomToken()) {
      throw {
        name: 'zoom error',
        message: 'Your zoom token has expired please sign in again'
      };
    }
    const response = await axios.post(`zoom/getmeetings`, {
      zoomToken: JSON.parse(localStorage.getItem('zoomToken'))['zoomToken'],
      meetingId
    });
    dispatch(slice.actions.getMeetings(response.data));
  } catch (error) {
    console.log('slices > zoom.js > getMeetings', error);
    return Promise.reject(error);
  }
};

export const getRecording = meetingUUID => async dispatch => {
  try {
    if (!CheckZoomToken()) {
      throw {
        name: 'zoom error',
        message: 'Your zoom token has expired please sign in again'
      };
    }
    const response = await axios.post(`zoom/getrecording`, {
      zoomToken: JSON.parse(localStorage.getItem('zoomToken'))['zoomToken'],
      meetingUUID
    });
    dispatch(slice.actions.getRecording(response.data));
  } catch (error) {
    console.log('slices > zoom.js > getRecording', error);
    return Promise.reject(error);
  }
};

export const getParticipants = meetingUUID => async dispatch => {
  try {
    if (!CheckZoomToken()) {
      throw {
        name: 'zoom error',
        message: 'Your zoom token has expired please sign in again'
      };
    }
    const response = await axios.post(`zoom/getparticipants`, {
      zoomToken: JSON.parse(localStorage.getItem('zoomToken'))['zoomToken'],
      meetingUUID
    });

    dispatch(slice.actions.getParticipants(response.data));
  } catch (error) {
    console.log('slices > zoom.js > getParticipants', error);
    return Promise.reject(error);
  }
};

// update track meeting id
export const updateMeetingId = async (id, meetingId) => {
  try {
    await axios.put(`zoom/updateMeetingId/${id}`, { meetingId });
  } catch (error) {
    console.log('slices > zoom.js > updateMeetingId', error);
  }
};

export default slice;
