import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  createProgress,
  getProgress,
  updateProgress
} from 'src/slices/progress';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { CheckCircle as Checked } from 'react-feather';
import { useDispatch, useSelector } from 'src/store';
import useAuth from 'src/hooks/useAuth';

function CircularProgressWithLabel(props) {
  return (
    <CircularProgress
      style={{ padding: '10px' }}
      variant="determinate"
      {...props}
    />
  );
}

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  checkIcon: {
    marginRight: '5px',
    color: 'green'
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));

const NavItem = ({
  children,
  className,
  depth,
  videoSlug,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  lessonSlug,
  videoId,
  ...rest
}) => {
  const { user, userType } = useAuth();
  const { profileId } = user.profile[userType];
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(openProp);
  const { courseSlug } = useParams();
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const { progress } = useSelector(state => state.progress);

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };
  useEffect(() => {
    if (!videoSlug) return;
    dispatch(getProgress({ slug: videoSlug, profileId, videoId }));
  }, [dispatch, videoSlug]);

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        // exact
        style={style}
        to={`/app/videos/courses/${courseSlug}/${lessonSlug}/${videoSlug}`}
        // to="/app/videos/lessons/video"
      >
        <Grid container justify="space-between">
          <Grid item className={classes.title}>
            {title}
          </Grid>

          <Grid item>
            {progress[videoSlug]?.finished === true ? (
              <Checked className={classes.checkIcon} size="15" />
            ) : (
              // : progress[videoSlug]?.percentage > 0 ? (
              //   <CircularProgressWithLabel
              //     value={progress[videoSlug]?.percentage}
              //   />
              // )
              ''
            )}
          </Grid>
        </Grid>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  videoSlug: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
