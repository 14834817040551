import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const slice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    initialize(state, action) {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    },

    login(state, action) {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    },
    logout(state, action) {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
  }
});
const reducer2 = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const reducer = slice.reducer;
