import React from 'react';
import { useTheme } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
const Logo = props => {
  const theme = useTheme();

  const image =
    theme.name === 'LIGHT'
      ? 'https://i.postimg.cc/nrfyhwZ2/CODED-Logo-Navy.png'
      : 'https://i.postimg.cc/NFFN9Kqw/CODED-Logo-White.png';

  return (
    <img width="60" alt="Logo" src={image} {...props} />
    // <Typography style={{ color: 'inherit', textDecoration: 'inherit' }}>
    //   CODED Lab
    // </Typography>
  );
};
export const LogoAlt = props => {
  const theme = useTheme();
  // const image = theme.name === 'LIGHT' ? '#000' : '#fff';
  const image =
    theme.name === 'LIGHT'
      ? 'https://i.postimg.cc/nrfyhwZ2/CODED-Logo-Navy.png'
      : 'https://i.postimg.cc/NFFN9Kqw/CODED-Logo-White.png';
  return (
    <img width="60" alt="Logo" src={image} {...props} />
    // <Typography color="primary" variant="h1">
    //   CODED Lab
    // </Typography>
  );
};

export default Logo;
